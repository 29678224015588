import filter from "lodash/filter";
import { isNullOrUndefined } from "../../functions/util";

import {
  CLEAR_CREATE_PAGE,
  OPEN_CAMPAIGN_FORM_PAGE,
  CLOSE_CAMPAIGN_FORM_PAGE,
  OPEN_PLAN_FORM_PAGE,
  CLOSE_PLAN_FORM_PAGE,
  OPEN_ORDER_FORM_PAGE,
  CLOSE_ORDER_FORM_PAGE,
  SET_ORDER_BUYING_STATUSES,
  OPEN_CLIENT_STATUS_LOG_DIALOG,
  CLOSE_CLIENT_STATUS_LOG_DIALOG,
  OPEN_PROJECT_DIALOG,
  CLOSE_PROJECT_DIALOG,
  OPEN_ORDER_BUYING_STATUSES_DIALOG,
  CLOSE_ORDER_BUYING_STATUSES_DIALOG,
  OPEN_SET_ACTUAL_DIALOG,
  CLOSE_SET_ACTUAL_DIALOG,
  OPEN_PLAN_EXTENSION_FORM_PAGE,
  CLOSE_PLAN_EXTENSION_FORM_PAGE,
  OPEN_SET_CAMPAIGN_ACTUAL_DIALOG,
  CLOSE_SET_CAMPAIGN_ACTUAL_DIALOG,
  OPEN_PLAN_EXPORT_DIALOG,
  CLOSE_PLAN_EXPORT_DIALOG,
  UPDATE_PLANS_WITH_INSERTION_CHANGE,
  UPDATE_ORDERS_WITH_INSERTION_CHANGE,
  UPDATE_INSERTIONS_WITH_CHANGE,
  OPEN_SIGNATURE_STATUS_DIALOG,
  CLOSE_SIGNATURE_STATUS_DIALOG
} from "../constants/campaignDashboardConstants";

const initialState = {
  isCampaignFormOpen: false,
  isPlanFormOpen: false,
  isPlanExtensionFormOpen: false,
  isOrderFormOpen: false,
  isProjectDialogOpen: false,
  isOrderBuyingStatusesDialogOpen: false,
  isSetCampaignActualDialogOpen: false,
  isSetActualDialogOpen: false,
  isClientStatusLogDialogOpen: false,
  isPlanExportDialogOpen: false,
  isSignatureStatusDialogOpen: false,
  orderBuyingStatuses: {
    planId: 0,
    items: []
  },
  plansWithInsertionChange: [],
  ordersWithInsertionChange: [],
  insertionsWithChange: []
};

export const updatePlanWithInsertionChange = (state, payload) => {
  if (!isNullOrUndefined(payload.planId)) {
    const { plansWithInsertionChange } = state;
    return filter(plansWithInsertionChange, item => {
      return item !== payload.planId;
    });
  }

  if (!isNullOrUndefined(payload.plansWithInsertionChange)) {
    return payload.plansWithInsertionChange;
  }
};

export const updateOrdersWithInsertionChange = (state, payload) => {
  if (!isNullOrUndefined(payload.orderId)) {
    const { ordersWithInsertionChange } = state;
    return filter(ordersWithInsertionChange, item => {
      return item !== payload.orderId;
    });
  }

  if (!isNullOrUndefined(payload.ordersWithInsertionChange)) {
    return payload.ordersWithInsertionChange;
  }
};

export const updateInsertionsWithChange = (state, payload) => {
  if (!isNullOrUndefined(payload.insertionId)) {
    const { insertionsWithChange } = state;
    return filter(insertionsWithChange, item => {
      return payload.insertionId.indexOf(item.item1) < 0;
    });
  }

  if (!isNullOrUndefined(payload.insertionsWithChange)) {
    return payload.insertionsWithChange;
  }
};

const campaignDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CREATE_PAGE:
      return { ...initialState, stepper: state.stepper };
    case OPEN_CAMPAIGN_FORM_PAGE:
      return {
        ...state,
        isCampaignFormOpen: true
      };
    case CLOSE_CAMPAIGN_FORM_PAGE:
      return {
        ...state,
        isCampaignFormOpen: false
      };
    case OPEN_PLAN_FORM_PAGE:
      return {
        ...state,
        isPlanFormOpen: true
      };
    case CLOSE_PLAN_FORM_PAGE:
      return {
        ...state,
        isPlanFormOpen: false
      };
    case OPEN_PLAN_EXTENSION_FORM_PAGE:
      return {
        ...state,
        isPlanExtensionFormOpen: true
      };
    case CLOSE_PLAN_EXTENSION_FORM_PAGE:
      return {
        ...state,
        isPlanExtensionFormOpen: false
      };
    case OPEN_ORDER_FORM_PAGE:
      return {
        ...state,
        isOrderFormOpen: true
      };
    case CLOSE_ORDER_FORM_PAGE:
      return {
        ...state,
        isOrderFormOpen: false
      };
    case SET_ORDER_BUYING_STATUSES:
      return {
        ...state,
        orderBuyingStatuses: action.payload
      };
    case OPEN_CLIENT_STATUS_LOG_DIALOG:
      return {
        ...state,
        isClientStatusLogDialogOpen: true
      };
    case CLOSE_CLIENT_STATUS_LOG_DIALOG:
      return {
        ...state,
        isClientStatusLogDialogOpen: false
      };
    case OPEN_PROJECT_DIALOG:
      return {
        ...state,
        isProjectDialogOpen: true
      };
    case CLOSE_PROJECT_DIALOG:
      return {
        ...state,
        isProjectDialogOpen: false
      };
    case OPEN_ORDER_BUYING_STATUSES_DIALOG:
      return {
        ...state,
        isOrderBuyingStatusesDialogOpen: true
      };
    case CLOSE_ORDER_BUYING_STATUSES_DIALOG:
      return {
        ...state,
        isOrderBuyingStatusesDialogOpen: false
      };
    case OPEN_SET_ACTUAL_DIALOG:
      return {
        ...state,
        isSetActualDialogOpen: true
      };
    case CLOSE_SET_ACTUAL_DIALOG:
      return {
        ...state,
        isSetActualDialogOpen: false
      };
    case OPEN_SET_CAMPAIGN_ACTUAL_DIALOG:
      return {
        ...state,
        isSetCampaignActualDialogOpen: true
      };
    case CLOSE_SET_CAMPAIGN_ACTUAL_DIALOG:
      return {
        ...state,
        isSetCampaignActualDialogOpen: false
      };
    case OPEN_PLAN_EXPORT_DIALOG:
      return {
        ...state,
        isPlanExportDialogOpen: true
      };
    case CLOSE_PLAN_EXPORT_DIALOG:
      return {
        ...state,
        isPlanExportDialogOpen: false
      };
    case OPEN_SIGNATURE_STATUS_DIALOG:
      return {
        ...state,
        isSignatureStatusDialogOpen: true
      };
    case CLOSE_SIGNATURE_STATUS_DIALOG:
      return {
        ...state,
        isSignatureStatusDialogOpen: false
      };
    case UPDATE_PLANS_WITH_INSERTION_CHANGE:
      return {
        ...state,
        plansWithInsertionChange: updatePlanWithInsertionChange(
          state,
          action.payload
        )
      };
    case UPDATE_ORDERS_WITH_INSERTION_CHANGE:
      return {
        ...state,
        ordersWithInsertionChange: updateOrdersWithInsertionChange(
          state,
          action.payload
        )
      };
    case UPDATE_INSERTIONS_WITH_CHANGE:
      return {
        ...state,
        insertionsWithChange: updateInsertionsWithChange(state, action.payload)
      };
    default:
      return state;
  }
};

export default campaignDashboardReducer;
