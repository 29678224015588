import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Alert,
  TextField,
  Autocomplete,
  CircularProgress
} from "@mui/material";

import Checkbox from "../../../form/controls/Checkbox";
import DialogTitle from "../../../shared/dialog/DialogTitle";
import PlanExportDialogActions from "./PlanExportDialogActions";
import { closePlanExportDialog } from "../../../../business/actions/campaignDashboardActions";
import {
  downloadExcel,
  getExcelComponents,
  getGroups,
  sendToSignature
} from "../../../../business/models/Plan/planActions";
import { getPlan } from "../../../../business/models/Plan/planSelectors";
import {
  DISABLED_CHECKBOXES,
  SEPARATED_COMPONENTS,
  CHILD_CHECKBOX_LEFT_MARGIN,
  CHECKBOXES_WITH_TOP_BORDER,
  THIRD_DIALOG_SECTION,
  AT_LEAST_ONE_MUST_BE_CHECKED,
  DIALOG_FAILED_TO_DISPLAY,
  HIDDEN_CHECKBOXES,
  ORDER_DETAILS_ID,
  STAY_CHECKED_WHEN_BUDGET_FLEX,
  ORDER_DETAILS,
  NEXT,
  DOWNLOAD,
  PIPE_SYMBOL,
  SELECT_ORDERS,
  GO_EXPORT_COMPONENTS,
  SEND_DOCUMENT_FOR_DIGITAL_SIGNATURES,
  SUBMIT,
  ERROR_MESSAGE_GROUP,
  ERROR_MESSAGE_EMAIL_TEXT,
  ERROR_MESSAGE_EMAIL_ADDRESS,
  ERROR_MESSAGE_DOCUMENT_NAME,
  ERROR_MESSAGE_DUAL_APPROVED_EMAIL
} from "../../../../business/constants/PlanExportDialogConstants";
import FootnoteTable from "./extensions/FootnoteTable";
import { classes, Typography } from "../styles/planExportDialog";
import { getOrders } from "../../../../business/models/Order/orderSelectors";
import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_TO_BE_CANCELLED
} from "../../../../configurations/appConstants";
import { parseDate } from "../../../../business/models/common/utils/clientUtils";
import AutoComplete from "../../../form/controls/AutoComplete";
import { validation } from "../../../../business/models/common/utils/validationUtils";

const PlanExportDialog = () => {
  const dispatch = useDispatch();

  const [planExport, setPlanExport] = useState([]);
  const [showRatecard, setShowRatecard] = useState(false);
  const [footnotes, setFootnotes] = useState([]);
  const [isRatecardChecked, setIsRatecardChecked] = useState(false);
  const [isPlanDialog, setIsPlanDialog] = useState(true);
  const [isOrderDialog, setIsOrderDialog] = useState(false);
  const [isSignatureDialog, setIsSignatureDialog] = useState(false);
  const [groupOptions, setGroupOptions] = useState([]);
  const [group, setGroup] = useState();
  const [groupError, setGroupError] = useState([]);
  const [emailText, setEmailText] = useState("");
  const [emailTextError, setEmailTextError] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [emailAddressesError, setEmailAddressesError] = useState(false);
  const [emailAddressesErrorText, setEmailAddressesErrorText] = useState(
    ERROR_MESSAGE_EMAIL_ADDRESS
  );
  const [documentName, setDocumentName] = useState("");
  const [documentNameError, setDocumentNameError] = useState(false);
  const [downloadButtonText, setDownloadButtonText] = useState(NEXT);
  const [signatureButtonText, setSignatureButtonText] = useState(
    SEND_DOCUMENT_FOR_DIGITAL_SIGNATURES
  );
  const [planOrder, setPlanOrder] = useState([]);
  const currentPlan = useSelector(getPlan);
  const currentOrders = useSelector(getOrders);
  const isForeignCurrency =
    currentPlan && currentPlan.currencySupplierId !== "EUR";

  const formulateAgreementName = () => {
    let existingAgreements = 0;
    if (
      currentPlan &&
      currentPlan.digitalSignatureAgreementId &&
      currentPlan.digitalSignatureAgreementId.length > 0
    ) {
      existingAgreements = currentPlan.digitalSignatureAgreementId.split(",")
        .length;
    }
    setDocumentName(`${currentPlan.planName} - ${existingAgreements + 1}`);
  };

  const formatMonetaryString = (
    currency,
    number,
    thousandSeparator,
    decimalSeparator
  ) => {
    if (!number) {
      return `${currency} 0.00`;
    }
    const formattedString = number
      .toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
      .replaceAll(",", PIPE_SYMBOL)
      .replaceAll(".", decimalSeparator)
      .replaceAll(PIPE_SYMBOL, thousandSeparator);

    return `${currency} ${formattedString}`;
  };

  useEffect(() => {
    const getPlanExport = async () => {
      const { data, showRatecardOption } = await dispatch(getExcelComponents());
      setPlanExport(data);
      setShowRatecard(showRatecardOption);
      const orderDetailsValue = data.filter(
        item => item.name === ORDER_DETAILS
      );
      setDownloadButtonText(
        orderDetailsValue[0].defaultValue ? NEXT : DOWNLOAD
      );
    };
    const getFilteredOrders = items => {
      const filteredOrders = items
        .filter(
          order =>
            order.statusId !== ORDER_STATUS_CANCELLED &&
            order.statusId !== ORDER_STATUS_TO_BE_CANCELLED
        )
        .map(item => {
          return {
            text: `${item.marathonOrderId}
            ${PIPE_SYMBOL}
            ${item.saleshouseId}
            ${PIPE_SYMBOL}
            ${item.description}
            ${PIPE_SYMBOL}
            ${parseDate(item.orderStartDate)} - 
            ${parseDate(item.orderEndDate)}
            ${PIPE_SYMBOL}
            ${formatMonetaryString(
              item.currencySupplierSymbol,
              item.netTotal,
              ".",
              ","
            )}`,
            key: item.marathonOrderId,
            id: item.orderId,
            checked: true
          };
        });
      setPlanOrder(filteredOrders);
    };
    getPlanExport();
    getFilteredOrders(currentOrders);
  }, [dispatch, currentOrders]);

  const isThirdSectionValid = () => {
    const areChecked = planExport
      .filter(p => THIRD_DIALOG_SECTION.includes(p.name))
      .map(p => p.defaultValue);

    return areChecked.includes(true);
  };

  const isDialogValid = () => {
    return isThirdSectionValid();
  };

  const handleDownload = () => {
    if (downloadButtonText === NEXT) {
      setIsPlanDialog(false);
      setIsOrderDialog(true);
      setIsSignatureDialog(false);
      setDownloadButtonText(DOWNLOAD);
      return;
    }
    if (isDialogValid()) {
      const clickedComponentIds = planExport
        .filter(p => p.defaultValue)
        .map(p => p.id);
      const footnotesCustom = footnotes
        .filter(f => f.value.trim() !== "")
        .map(f => f.value);
      const checkedOrderIds = planOrder.filter(o => o.checked).map(o => o.id);

      dispatch(
        downloadExcel(
          clickedComponentIds,
          footnotesCustom,
          isRatecardChecked,
          currentPlan.marathonPlanId,
          currentPlan.planName,
          checkedOrderIds
        )
      );
      dispatch(closePlanExportDialog());
    }
  };

  const getGroupData = async () => {
    const { group: groupList } = await dispatch(getGroups());
    setGroupOptions(groupList);
  };

  const IsEmailAddressValid = clickedComponentIds => {
    let emailAddressValid = true;

    if (emailAddresses.length < 1) {
      setEmailAddressesError(true);
      setEmailAddressesErrorText(ERROR_MESSAGE_EMAIL_ADDRESS);
      emailAddressValid = false;
    } else {
      emailAddressValid = true;
      if (emailAddresses.some(x => validation.email(x) === false)) {
        setEmailAddressesError(true);
        setEmailAddressesErrorText(ERROR_MESSAGE_EMAIL_ADDRESS);
        emailAddressValid = false;
      } else {
        emailAddressValid = true;
        if (
          clickedComponentIds.some(x => x === 151) &&
          emailAddresses.length < 2
        ) {
          setEmailAddressesError(true);
          setEmailAddressesErrorText(ERROR_MESSAGE_DUAL_APPROVED_EMAIL);
          emailAddressValid = false;
        }
      }
    }

    return emailAddressValid;
  };

  const IsSignatureSubmissionDataValid = clickedComponentIds => {
    let dataValid = true;

    if (!group) {
      setGroupError(groupErr => [...groupErr, ERROR_MESSAGE_GROUP]);
      dataValid = false;
    }

    if (emailText.length < 1) {
      setEmailTextError(true);
      dataValid = false;
    }

    if (documentName.length < 1) {
      setDocumentNameError(true);
      dataValid = false;
    }

    if (!IsEmailAddressValid(clickedComponentIds)) {
      dataValid = false;
    }

    return dataValid;
  };

  const handleSignature = () => {
    const clickedComponentIds = planExport
      .filter(p => p.defaultValue)
      .map(p => p.id);

    if (signatureButtonText === SEND_DOCUMENT_FOR_DIGITAL_SIGNATURES) {
      setIsPlanDialog(false);
      setIsOrderDialog(false);
      setIsSignatureDialog(true);
      setSignatureButtonText(SUBMIT);
      formulateAgreementName();
      getGroupData();
      return;
    }

    if (!IsSignatureSubmissionDataValid(clickedComponentIds)) {
      return;
    }

    const footnotesCustom = footnotes
      .filter(f => f.value.trim() !== "")
      .map(f => f.value);
    const checkedOrderIds = planOrder.filter(o => o.checked).map(o => o.id);

    dispatch(
      sendToSignature(
        clickedComponentIds,
        footnotesCustom,
        isRatecardChecked,
        checkedOrderIds,
        emailText,
        emailAddresses,
        documentName,
        group
      )
    );
    dispatch(closePlanExportDialog());
  };

  const handleCancelPlanExportDialog = () => dispatch(closePlanExportDialog());

  const shouldErrorBeDisplay = name => {
    return THIRD_DIALOG_SECTION[0] === name && !isThirdSectionValid();
  };

  const shouldChildCheckboxesReset = (
    checked,
    clickedCheckboxName,
    checkbox
  ) => {
    return (
      !checked &&
      SEPARATED_COMPONENTS[clickedCheckboxName]?.includes(checkbox.name)
    );
  };

  const handleCheckBoxClick = (checked, fieldName) => {
    if (fieldName === ORDER_DETAILS) {
      setDownloadButtonText(checked ? NEXT : DOWNLOAD);
      if (!checked) {
        setIsRatecardChecked(checked);
      }
    }

    setPlanExport(
      planExport.map(p => {
        if (shouldChildCheckboxesReset(checked, fieldName, p)) {
          return { ...p, defaultValue: false };
        }
        if (p.name === fieldName) {
          return { ...p, defaultValue: checked };
        }
        return p;
      })
    );
  };

  const isCheckboxDisabled = name => {
    return (
      DISABLED_CHECKBOXES.includes(name) ||
      !!Object.entries(SEPARATED_COMPONENTS).find(
        ([key, value]) =>
          !!planExport.find(plan => plan.name === key && !plan.defaultValue) &&
          value.includes(name)
      )
    );
  };

  const isCheckboxHidden = name => {
    return HIDDEN_CHECKBOXES.includes(name);
  };

  const checkboxStyle = name => {
    if (CHECKBOXES_WITH_TOP_BORDER.includes(name)) return { borderTop: 1 };

    if (Object.values(SEPARATED_COMPONENTS).find(sc => sc.includes(name)))
      return { ml: CHILD_CHECKBOX_LEFT_MARGIN };

    return {};
  };

  const updateFootnotes = data => {
    setFootnotes(data);
  };
  const handleOrderCheckBoxClick = (selected, id) => {
    setPlanOrder(
      planOrder.map(p => {
        if (p.key === id) {
          return { ...p, checked: selected };
        }
        return p;
      })
    );
  };

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle id="excel-export-dialog-title">
        {isPlanDialog && GO_EXPORT_COMPONENTS}
        {isOrderDialog && SELECT_ORDERS}
        {isSignatureDialog && SEND_DOCUMENT_FOR_DIGITAL_SIGNATURES}
      </DialogTitle>
      {isForeignCurrency && isPlanDialog && (
        <Alert severity="warning">
          Plan is in foreign currency, please double-check amounts.
        </Alert>
      )}
      {isOrderDialog && planOrder.length < 1 && (
        <Alert severity="error">No Orders found.</Alert>
      )}
      <DialogContent>
        {isOrderDialog &&
          planOrder &&
          planOrder.map(item => {
            return (
              <Grid container key={item.key}>
                <Checkbox
                  onChange={e => handleOrderCheckBoxClick(e, item.key)}
                  id={`checkboxOrder${item.key}`}
                  label={item.text}
                  value={item.checked}
                  color="success"
                />
              </Grid>
            );
          })}
        {isPlanDialog && (
          <Grid container>
            {planExport ? (
              <>
                {planExport.map(p => {
                  const { id, name, defaultValue } = p;
                  return (
                    <Grid item key={id} xs={12} sx={checkboxStyle(name)}>
                      <Checkbox
                        onChange={e => handleCheckBoxClick(e, name)}
                        error={shouldErrorBeDisplay(name)}
                        errorMessage={AT_LEAST_ONE_MUST_BE_CHECKED}
                        label={name}
                        value={defaultValue}
                        color="success"
                        disabled={isCheckboxDisabled(name)}
                        hidden={isCheckboxHidden(name)}
                      />
                      {ORDER_DETAILS_ID === id && (
                        <>
                          <Typography className={classes.typographyCaptionBold}>
                            {STAY_CHECKED_WHEN_BUDGET_FLEX}
                          </Typography>
                          {showRatecard && (
                            <Grid
                              container
                              sx={{ ml: CHILD_CHECKBOX_LEFT_MARGIN }}
                            >
                              <Checkbox
                                onChange={e => setIsRatecardChecked(e)}
                                label="Export Ratecard"
                                value={isRatecardChecked}
                                color="info"
                                disabled={!defaultValue}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  );
                })}

                {planExport.length > 0 && (
                  <FootnoteTable footnotes={updateFootnotes} />
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <Alert severity="error">{DIALOG_FAILED_TO_DISPLAY}</Alert>
              </Grid>
            )}
          </Grid>
        )}
        {isSignatureDialog && (
          <Grid id="grid-signature" container spacing={3}>
            <Grid item xs={8}>
              <AutoComplete
                id="select-adobe-group"
                name="select-adobe-group"
                options={groupOptions}
                value={group}
                onChange={event => {
                  setGroup(event);
                  setGroupError([]);
                }}
                disabled={groupOptions.length < 1}
                label="Select Adobe Group *"
                displayErrors={groupError.length !== 0}
                errors={groupError}
              />
            </Grid>
            <Grid item xs={2} alignContent="center">
              {groupOptions.length < 1 && (
                <CircularProgress size={30}></CircularProgress>
              )}
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="client-email-text"
                name="client-email-text"
                label="Client Email Text *"
                type="text"
                value={emailText}
                onChange={event => {
                  setEmailText(event.target.value);
                  setEmailTextError(event.target.value.length < 1);
                }}
                fullWidth
                size="medium"
                variant="outlined"
                inputProps={{
                  maxLength: 250
                }}
                error={emailTextError}
                helperText={emailTextError && ERROR_MESSAGE_EMAIL_TEXT}
              />
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                id="ac-client-email-address"
                name="ac-client-email-address"
                autoHighlight
                freeSolo
                multiple
                filterSelectedOptions
                options={[]}
                ChipProps={{ color: "primary" }}
                clearOnBlur
                renderInput={params => {
                  return (
                    <>
                      <TextField
                        {...params}
                        id="client-email-address"
                        name="client-email-address"
                        label="Client Email Address* (Type the email address and press enter...)"
                        variant="outlined"
                        error={emailAddressesError}
                        helperText={
                          emailAddressesError && emailAddressesErrorText
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: params.InputProps.endAdornment,
                          maxLength: 1000
                        }}
                      />
                    </>
                  );
                }}
                onChange={(_, data, reason, detail) => {
                  const email = reason === "removeOption" ? "" : detail.option;
                  setEmailAddresses(data);
                  setEmailAddressesError(!validation.email(email));
                }}
              />
            </Grid>
            <Grid item xs={8} className={classes.root}>
              <TextField
                id="agreement-name"
                name="agreement-name"
                label="Agreement/Document Name *"
                type="text"
                value={documentName}
                onChange={event => {
                  setDocumentName(event.target.value);
                  setDocumentNameError(event.target.value.length < 1);
                }}
                fullWidth
                size="medium"
                variant="outlined"
                inputProps={{
                  maxLength: 100
                }}
                error={documentNameError}
                helperText={documentNameError && ERROR_MESSAGE_DOCUMENT_NAME}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <PlanExportDialogActions
          isDownloadDisabled={!planExport}
          onCancelClick={handleCancelPlanExportDialog}
          onDownloadClick={handleDownload}
          onSignatureClick={handleSignature}
          downloadButtonText={downloadButtonText}
          signatureButtonText={signatureButtonText}
        />
      </DialogActions>
    </Dialog>
  );
};

export default PlanExportDialog;
