import React from "react";
import { useSelector } from "react-redux";

import { Grid } from "@mui/material";

import ProjectField from "../../../form/ProjectField";
import { getCampaignData } from "../../../../business/models/Campaign/campaignSelectors";

const ProjectForm = () => {
  const { isDebtorActive } = useSelector(getCampaignData) || false;

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <ProjectField
            component="text"
            label="Campaign"
            id="marathonCampaignId"
            name="marathonCampaignId"
            disabled
          />
        </Grid>
        <Grid item>
          <ProjectField
            component="text"
            label="Marathon Project ID"
            id="marathonProjectId"
            name="marathonProjectId"
            disabled
          />
        </Grid>
      </Grid>
      <ProjectField
        label="Project name*"
        id="projectName"
        name="projectName"
        component="text"
        disabled={!isDebtorActive}
      />
      <ProjectField
        label="Project manager*"
        id="projectLeaderId"
        name="projectLeaderId"
        component="text"
        disabled={!isDebtorActive}
      />
      <ProjectField
        label="Group"
        id="projectGroup"
        name="projectGroup"
        component="text"
        disabled
      />
      <ProjectField
        label="Job Type*"
        id="jobTypeId"
        name="jobTypeId"
        component="autocomplete"
        disabled={!isDebtorActive}
      />
      <Grid container sx={{ paddingTop: 2 }} columnSpacing={3}>
        <Grid item xs={6}>
          <ProjectField
            label="Project Start Date"
            id="projectStartDate"
            name="projectStartDate"
            component="date"
            disabled={!isDebtorActive}
          />
        </Grid>
        <Grid item xs={6}>
          <ProjectField
            label="Project End Date"
            id="projectEndDate"
            name="projectEndDate"
            component="date"
            disabled={!isDebtorActive}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectForm;
