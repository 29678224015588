import { styled, Grid as MuiGrid } from "@mui/material";
import {
  supernova,
  white,
  crusta,
  dodgerBlue
} from "../../../../assets/styles/colors";

const PREFIX = "PlanChangeOverviewDialogActionsStyles";
export const classes = {
  cancelButton: `${PREFIX}-cancelButton`,
  downloadButton: `${PREFIX}-downloadButton`,
  signatureButton: `${PREFIX}-signatureButton`
};

export const Grid = styled(MuiGrid)(() => ({
  [`& .${classes.cancelButton}`]: {
    color: crusta
  },
  [`& .${classes.downloadButton}`]: {
    color: white,
    backgroundColor: supernova
  },
  [`& .${classes.signatureButton}`]: {
    color: white,
    backgroundColor: dodgerBlue
  }
}));
