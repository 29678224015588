import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { Button } from "@mui/material";

import { classes, Grid } from "../styles/planChangeOverviewDialogActions";
import {
  NEXT,
  SUBMIT
} from "../../../../business/constants/PlanExportDialogConstants";

const PlanExportDialogActions = ({
  isDownloadDisabled,
  onCancelClick,
  onDownloadClick,
  onSignatureClick,
  downloadButtonText,
  signatureButtonText
}) => {
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Button
            onClick={() => onCancelClick(false)}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            {signatureButtonText !== SUBMIT && (
              <Grid item>
                <Button
                  disabled={isDownloadDisabled}
                  onClick={onDownloadClick}
                  className={clsx(classes.downloadButton)}
                >
                  {downloadButtonText}
                </Button>
              </Grid>
            )}
            {downloadButtonText !== NEXT && (
              <Grid item>
                <Button
                  disabled={isDownloadDisabled}
                  onClick={onSignatureClick}
                  className={clsx(classes.signatureButton)}
                >
                  {signatureButtonText}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

PlanExportDialogActions.propTypes = {
  onCancelClick: PropTypes.func.isRequired
};

export default PlanExportDialogActions;
